.circle-image {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    text-align: center;
    object-fit: cover;
}

.rounded-image {
    border-radius: 12px;
    object-fit: cover;
}

.square-image {
    border-radius: 0px;
    object-fit: cover;
}

.xxsmall {
    width: 20px;
    height: 20px;
}


.xsmall {
    width: 40px;
    height: 40px;
}

.small {
    width: 54px;
    height: 54px;
}

.medium {
    width: 80px;
    height: 80px;
}

.large {
    width: 120px;
    height: 120px;
}
.xlarge {
    width: 200px;
    height: 200px;
}