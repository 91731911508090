.App {
  min-height: 100vh;
  max-width: 100vw;
  text-align: center;
  background-color: #61E5BE;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.heading {
  font-weight: 700;
  font-size: x-large;
}

.sub-heading {
  font-weight: 600;
  font-size: large;
}

.title {
  font-weight: 600;
  font-size: 800;
}

.subtitle {
  font-weight: 400;
  font-size: 600;
}

.header {
  min-height: 80px;
}

.title-group {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  padding: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-box {
  height: 54px;
  border-radius: 27px;
  padding-left: 16px;
  padding-right: 16px;
}

.padded {
  margin-left: 12px;
  margin-right: 12px;
}

.padded-vert {
  margin-top: 8px;
  margin-bottom: 8px;
}

.form {
  text-align: left;
}

.body {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.center-body {
  margin: auto;
  width: 360px;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.check-box {
  margin-left: 6px;
}

.group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 18px;
}

a {
  color: white;
}

.announcement {
  font-weight: bold;
  font-size: x-large;
}

p {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  height: 200px;
  vertical-align: center;
  margin: auto;
}

th {
  border-bottom: 1px solid black;
  vertical-align: center;
  margin: auto;
}

td {
  text-align: center;
  vertical-align: center;
  align-items: center;
  margin: auto;
  /* height: 20px; */
}

tbody {
  vertical-align: center;
  margin: auto;
}

.wrapper-button {
  background-color: transparent;
  border-color: transparent;
}

.row {
  display: inline-flex;
  justify-content: space-between;
}

.row-group {
  display: inline-flex;
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  position: relative;
  align-items: center;
  text-align: left;
}

.row-end {
  margin-right: 0px;
}

.fill {
  min-width: 100%;
  min-height: 100%;
}

.spacer {
  min-width: 100%;
}

.section {
  max-width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.item {
  max-width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.code-block {
  max-width: 86vw;
  width: 100%;
  text-align: left;
  margin: 0 0px 0px 0px;
}

.align-right {
  align-items: right;
  text-align: right;
}