.announce-button {
    background-color: black;
    border: none;
    color: white;
    padding: 14px 20px;
    text-decoration: none;
    display: inline-block;
    margin: 4px 4px;
    cursor: pointer;
    border-radius: 27px;
    text-align: center;

    max-width: 100%;
    font-weight: bold;
}

.primary-button {
    background-color: white;
    border: none;
    color: black;
    padding: 14px 20px;
    text-decoration: none;
    display: inline-block;
    margin: 4px 4px;
    cursor: pointer;
    border-radius: 27px;
    text-align: center;
    max-width: 100%;
    font-weight: bold;
}

.secondary-button {
    background-color: lightgray;
    border: none;
    color: black;
    padding: 14px 20px;
    text-decoration: none;
    display: inline-block;
    margin: 4px 4px;
    cursor: pointer;
    border-radius: 27px;
    text-align: center;
    max-width: 100%;
    font-weight: bold;
    height: 54;
}


.outline-button {
    background-color: transparent;
    border-color: black;
    color: black;
    padding: 14px 20px;
    text-decoration: none;
    display: inline-block;
    margin: 4px 4px;
    cursor: pointer;
    border-radius: 27px;
    text-align: center;
    max-width: 100%;
    font-weight: bold;
}

.simple-button {
    background-color: transparent;
    background-repeat: no-repeat;
    border-color: black;
    border: none;
    color: black;
    padding: 14px 20px;
    text-decoration: none;
    display: inline-block;
    margin: 4px 4px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
}

.link-button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    color: black;
    cursor: pointer;
    font-weight: normal;
    text-align: center;
    display: inline-block;
}

.circle-button {
    height: 200px;
    width: 200px;
    border-radius: 50%;

    background-color: black;
    border: none;
    color: white;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}

.glyph-button {
    border-radius: 4px;
    border: none;
    background-color: lightgray;
    color: white;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-width: 0px;
    border-style: solid;
    padding-top: 0px;
    padding-bottom: 3px;
}

.glyph-button:focus {
    background-color: white;
}

.glyph {
    height: 16px;
    width: 16px;
    margin: auto;
}
