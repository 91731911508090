.header-group {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  align-content: center;
  vertical-align: center;
  max-width: 1000px;
}

.header-item {
  margin-left: 12px;
  margin-right: 12px;
}

.header-name {
  margin: auto;
  margin-right: 12px;
  font-size: larger;
  font-weight: bold;
  vertical-align: center;
}

.header-basin-name {
  margin: auto;
  margin-right: 12px;
  font-size: larger;
  font-weight: 600;
  vertical-align: center;
}

.header {
  background-color: #61E5BE;
  display: flex;
  justify-content: space-between;
  min-height: 24px;
  padding-top: 24px;
  padding-right: 44px;
  padding-left: 24px;
  vertical-align: center;
}

.header-logo {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.header-avatar {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.link {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  color: black;
  font-size: large;
  font-weight: 600;
  text-decoration: none;
}

.link:focus:hover:focus-visible {
  color: black;
  background-color: black;
}

.layout-body {
  min-height: 70vh;
  margin: 42px;
}