.footer-buttons {
    display: contents;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.footer {
    min-height: 120px;
    background-color: #61E5BE;
    margin: auto;
}